<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 11:46:04
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-01-03 17:29:06
 * @FilePath: \nc-funeral-mobile\src\views\complaint\list.vue
-->
<template>
  <div class="page-container page-container-100 complaint">
    <van-list
      v-model="paginationList.loading"
      :finished="paginationList.finished"
      finished-text="没有更多了"
      @load="onLoad"
      error-text="加载失败,点击重新加载"
    >
      <div
        v-for="(item, index) in paginationList.list"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="complaint-datetime">{{ item.createTime }}</div>
        <div class="complaint-box">
          <div class="title">{{ item.reportName }}</div>
          <div class="content">{{ item.reportRemarks }}</div>
          <div class="more">
            <div>查看详情</div>
            <van-icon class="icon" name="arrow" />
          </div>
        </div>
      </div>
    </van-list>
    <!-- 添加按钮 -->
    <div class="added-icon" @click="toPage">
      <img class="icon" src="../../assets/images/added-icon.png" />
    </div>
  </div>
</template>
<script>
import { getComplaintList } from "@/api/complaint.js";
export default {
  name: "ComplaintList",
  data() {
    return {
      complaintList: [],
      paginationList: {
        current: 1, //当前页
        size: 10, //一页多少条
        total: 0, //总共多少条
        list: [], //本地list
        loading: false,
        finished: false,
        error: false,
      },
      complaintId:'',
    };
  },
  created() {
    // this.getComplaintList();
    
  },
  mounted() {},
  methods: {
   
    // 获取投诉建议列表
    async getComplaintList() {
      let cplList = this.paginationList.list;
      let params = {
        pageNo: this.paginationList.current,
        pageSize: this.paginationList.size,
      };
      let [err, res] = await this.$awaitWrap(getComplaintList(params));
      if (err) {
        return;
      }
      let data = res.result;
      if (data && data.records.length > 0) {
        console.log(data.records);
        for (let i = 0; i < data.records.length; i++) {
          let newObj = {
            ...data.records[i],
          };
          cplList.push(newObj);
        }
        this.paginationList.list = cplList;
        this.paginationList.total = data.total;
        this.paginationList.loading = false;
        if (this.paginationList.list.length >= data.total) {
          this.paginationList.finished = true; // 结束加载状态
        }
      } else {
        this.paginationList.finished = true;
      }
    },
    onLoad() {
      let timer = setTimeout(() => {
        if (this.paginationList.total == 0) {
          this.getComplaintList();
          this.paginationList.current = this.paginationList.current + 1;
          this.paginationList.finished && clearTimeout(timer);
        } else {
          let list = this.paginationList.list;
          let total = this.paginationList.total;
          if (list.length < total) {
            this.getComplaintList();
            this.paginationList.current = this.paginationList.current + 1;
            this.paginationList.finished && clearTimeout(timer);
          }
        }
      }, 100);
    },
    toDetails(item) {
      this.$router.push({
        path: "/complaint/add",
        query: { id: item.reportId },
      });
    },
    toPage() {
      this.$router.push({ path: "/complaint/add" });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/index.less";
</style>